import api from './api';

class MenuService {
  get(id) {
    return api.get(`/menu/${id}`).then((res) => res.data);
  }

  find(params) {
    return api.get('/menu', { params }).then((res) => res.data);
  }

  findToOptions() {
    return api.get('/menu/full-list').then((res) => res.data);
  }

  create(data) {
    return api.post(`/menu`, data).then((res) => res.data);
  }

  update(id, data) {
    return api.patch(`/menu/${id}`, data).then((res) => res.data);
  }

  delete(id) {
    return api.delete(`/menu/${id}`).then((res) => res.data);
  }

  getAvailable() {
    return api.get(`/menu/available`).then((res) => res.data);
  }

  setAvailable(menuId, day) {
    return api.post(`/menu/available`, { day, menuId }).then((res) => res.data);
  }
}

export default new MenuService();
