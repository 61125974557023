<script>
import MenuService from '@/service/menu.service';

export default {
  name: 'menu-schedule',

  components: {},

  data() {
    return {
      loading: false,
      form: {},
      items: [],
      options: [],
      weekDays: ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      selectedOption: [],
    };
  },

  watch: {},

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      this.loading = true;

      try {
        const data = await MenuService.getAvailable();
        this.options = await MenuService.findToOptions();

        data.forEach((element) => {
          this.selectedOption[element.day] = element.menuId;
          this.items[element.day] = this.options.find((e) => e.id === element.menuId);
        });
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    async optionSelected(day) {
      try {
        await MenuService.setAvailable(this.selectedOption[day], day);
        this.fetch();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<template>
  <div class="mt-10">
    <v-row>
      <v-col cols="12">
        <h3 class="pb-1 mb-2">Cronograma de merendas</h3>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3 class="pb-1 mb-2">Matutino</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="n in Array.from({ length: 6 }, (_, i) => i)" v-bind:key="`sheduler-days-${i}`" cols="12" sm="2">
        <v-card :loading="loading">
          <v-card-title> {{ weekDays[n] }} </v-card-title>
          <v-card-text>
            <v-select
              v-model="selectedOption[n]"
              :label="'Cardápio'"
              :items="options"
              single-line
              bottom
              item-text="name"
              item-value="id"
              outlined
              @change="() => optionSelected(n)"
            ></v-select>
            <v-flex v-if="items[n]">
              <p>{{ items[n].items.map((item) => item.name).join(', ') }}</p>
              <span
                >Calorias: {{ items[n].items.map((item) => item.calorific_value).reduce((a, b) => a + b, 0) }} cal</span
              ><br />
              <span>Proteínas: {{ items[n].items.map((item) => item.protein).reduce((a, b) => a + b, 0) }} g</span>
            </v-flex>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <h3 class="pb-1 mb-2">Vespertino</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="n in Array.from({ length: 6 }, (_, i) => i + 6)" v-bind:key="`sheduler-days-${i}`" cols="12" sm="2">
        <v-card :loading="loading">
          <v-card-title> {{ weekDays[n - 6] }} </v-card-title>
          <v-card-text>
            <v-select
              v-model="selectedOption[n]"
              :label="'Cardápio'"
              :items="options"
              single-line
              bottom
              item-text="name"
              item-value="id"
              outlined
              @change="() => optionSelected(n)"
            ></v-select>
            <v-flex v-if="items[n]">
              <p>{{ items[n].items.map((item) => item.name).join(', ') }}</p>
              <span
                >Calorias: {{ items[n].items.map((item) => item.calorific_value).reduce((a, b) => a + b, 0) }} cal</span
              ><br />
              <span>Proteínas: {{ items[n].items.map((item) => item.protein).reduce((a, b) => a + b, 0) }} g</span>
            </v-flex>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
